import React from 'react'
import './UI.scss'
import UIHeader from './UIHeader'
import UIFooter from './UIFooter'
import UIOverlay from './UIOverlay'
import UIPortraitMode from './UIPortraitMode'

const UI = () => {
  return (
    <div className="ui">
      <UIHeader />
      <UIFooter />
      <UIOverlay />
      <UIPortraitMode />
    </div>
  )
}

export default UI