import React, { createContext, useContext, useReducer } from 'react'
import ModalShare from './comp/ModalShare'
import ModalAbout from './comp/ModalAbout'

export const defaultState = {
  showConfig: true,
  pageTitle: 'XYLOTap App',
  appName: 'XYLOTap',
  user: {id: 0, name: 'Anon'},
  isStandalone: false,
  modal: false,
  orientation: 'landscape'
}

// Detect stand-alone
// IOS
if (window.navigator.standalone) {
  defaultState.isStandalone = true
}
// Android
if (window.matchMedia('(display-mode: standalone)').matches) {
  defaultState.isStandalone = true
}

/**
 * Manage current App state
 */
export const StateContext = createContext()

export const StateProvider = ({reducer, initialState, children}) =>(
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)
export const useStateContext = () => useContext(StateContext)

export const stateReducer = (state, action) => {
  console.log(`%cSTATE.action.type: ${action.type}`, 'color: gray')
  switch (action.type) {
    case 'aboutShow':
      let modalAbout = {
        title: `About ${state.appName}`,
        body: ModalAbout
      }
      return {...state, modal: modalAbout}
    case 'shareShow':
      let modalShare = {
        title: 'Share',
        body: ModalShare
      }
      return {...state, modal: modalShare}
    case 'overlayClose':
      return {...state, modal: false}
    case 'updateConfig':
      return {...state, config: action.config}
    case 'toggleConfigUI':
      let showConfig = !state.showConfig
      return {...state, showConfig}
    case 'closeConfigUI':
      return {...state, modal: {showConfig: false}}
    case 'showConfigUI':
      return {...state, modal: {showConfig: true}}
    default:
      return state;
  }
}
