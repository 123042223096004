// import audioA3 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.A3.stereo.mp3'
import audioA4 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.A4.stereo.mp3'
import audioA5 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.A5.stereo.mp3'
// import audioB3 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.B3.stereo.mp3'
import audioB4 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.B4.stereo.mp3'
import audioC3 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.C3.stereo.mp3'
import audioC4 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.C4.stereo.mp3'
import audioD3 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.D3.stereo.mp3'
import audioD4 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.D4.stereo.mp3'
import audioE3 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.E3.stereo.mp3'
import audioE4 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.E4.stereo.mp3'
import audioF3 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.F3.stereo.mp3'
import audioF4 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.F4.stereo.mp3'
import audioG3 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.G3.stereo.mp3'
import audioG4 from '../../audio/marimba_bars_mp3/Marimba.yarn.ff.G4.stereo.mp3'

// Default bars
let bars = [
  {label: 'C3', id: 'c3', frequency: 32.70, audio: audioC3, length: 1},
  {label: 'D3', id: 'd3', frequency: 36.71, audio: audioD3, length: 0.95},
  {label: 'E3', id: 'e3', frequency: 41.20, audio: audioE3, length: 0.9},
  {label: 'F3', id: 'f3', frequency: 43.65, audio: audioF3, length: 0.85},
  {label: 'G3', id: 'g3', frequency: 49.00, audio: audioG3, length: 0.8},
  {label: 'A4', id: 'a4', frequency: 55.00, audio: audioA4, length: 0.75},
  {label: 'B4', id: 'b4', frequency: 61.74, audio: audioB4, length: 0.7},
  {label: 'C4', id: 'c4', frequency: 61.74, audio: audioC4, length: 0.65},
  {label: 'D4', id: 'd4', frequency: 61.74, audio: audioD4, length: 0.6},
  {label: 'E4', id: 'e4', frequency: 61.74, audio: audioE4, length: 0.55},
  {label: 'F4', id: 'f4', frequency: 61.74, audio: audioF4, length: 0.5},
  {label: 'G4', id: 'g4', frequency: 61.74, audio: audioG4, length: 0.45},
  {label: 'A5', id: 'a5', frequency: 61.74, audio: audioA5, length: 0.4},
]

module.exports = bars

