import React from 'react'
import './App.scss'

import Xylophone from './comp/Xylophone'
import UI from './comp/UI'

const App = () => {

  return (
    <div className="app">
      <Xylophone />
      <UI />
    </div>
  )
}

export default App