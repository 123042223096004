import React from 'react'
var QRCode = require('qrcode.react')
 
const QRCodeDisplay = ({size=256}) => {
  // const [{ modal }, dispatch] = useStateContext();
  // Default url to location
  let url = document.location.origin
  let originOverride = process.env.SHARE_ORIGIN
   // Check if running in devlopment mode
  if (document.location.hostname === 'localhost') {
    // Check env.SHARE_ORIGIN
    console.log(originOverride)
    if (originOverride) {
      url = url.replace('localhost', originOverride)
    }
  }

  const fpo =  process.env.SHARE_ORIGIN //JSON.stringify(process.env, null, 2)
  return (
    <>
      <QRCode value={url} size={size} />
    </>
  )
}

export default QRCodeDisplay