import React, {Component} from 'react'
import { useStateContext } from '../state'
import { IconShare, IconQRCode, IconCamera } from './Icons'

const UIHeader = () => {
  const [{appName, isStandalone}, dispatch] = useStateContext();
  const cameraHandler = (evt) => {
    evt.preventDefault()
    dispatch({type: 'qrScanShow'})
  }
  const shareHandler = (evt) => {
    evt.preventDefault()
    dispatch({type: 'shareShow'})
  }
  return (
    <div className="ui-header">
      <ul className="action-links">
        {isStandalone && 
        <li>
          <a href="#" onClick={cameraHandler}><IconCamera /><span>Scan</span></a>
        </li>
        }
        {/* <li>
          <a href="#" onClick={shareHandler}><IconShare /><span>Share</span></a>
        </li> */}
        <li>
          <a href="#" onClick={shareHandler}><IconQRCode /><span>QR</span></a>
        </li>
      </ul>
      <h3>{appName}</h3>
    </div>
  )
}

export default UIHeader