import React, { Fragment, useEffect } from 'react'
import { useStateContext } from '../state'

import { IconClose } from './Icons'

const GenericText = ({contents}) => {
  return (
    <div className="generic-text">
      {contents}
    </div>
  )
}

const UIOverlay = () => {
  const [{ modal }, dispatch] = useStateContext();

  const closeOverlay = (evt) => {
    evt.preventDefault()
    dispatch({type: 'overlayClose'})
  }

  const closeOverlayOnEscape = (evt) => {
    if (evt.key === 'Escape') {
      closeOverlay(evt)
    }
  }

  const onBackgroundClick = (evt) => {
    closeOverlay(evt)
  }

  useEffect(() => {
    // Listen for escape key and close oeverlay if pressed
    document.addEventListener('keydown', closeOverlayOnEscape)
    return () => {
      document.removeEventListener('keydown', closeOverlayOnEscape)
    }
  }, [])

  let contentBody = typeof (modal.body || '') === 'string' ? <GenericText contents={modal.body}/> : <modal.body />
  
  return (
    <Fragment>
      {modal &&
        <div className="ui-overlay" >
          <div className="ui-overlay__background" onClick={onBackgroundClick}></div>
          <div className="ui-overlay__window">
            <div className="ui-overlay__actions">
              <a href="#" onClick={closeOverlay} className="close action-link"><IconClose /></a>
            </div>
            {modal.title && 
              <div className="ui-overlay__header">
                <h2>{modal.title}</h2>
              </div>
            }
            <div className="ui-overlay__body">
              {contentBody}
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default UIOverlay