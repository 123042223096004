import React, {useState, useEffect, useRef} from 'react'
import {default as defaultBars} from '../config/bars'
import xylo from '../pixi/xylo'
import './Xylophone.scss'


const Bars = ({activeBars = []}) => {
  let app, sounds
  const pixiContainerRef = useRef(null)
  
  const setup = () => {
    xylo({
      el: pixiContainerRef.current,
      bars: defaultBars
    })
  }

  useEffect(setup, [])
  
  return (
    <div className="bars">
      <div className="pixi-container" ref={pixiContainerRef} style={{width: '100%'}}></div>
    </div>
  )
}

const Xylophone = () => {
  const [activeBars, setActiveBars] = useState(defaultBars);
  return (
    <div className="xylophone instrument instrument-active">
      <Bars activeBars={activeBars} />
    </div>
  );
}

export default Xylophone