import React from 'react'
import { useStateContext } from '../state'
import { IconEdit, IconQRCode, IconCamera, IconInfo, IconSettings } from './Icons'
import StateViewer from './StateViewer'

const UIFooter = () => {
  const [state, dispatch] = useStateContext();
  const onClickInfo = (evt) => {
    evt.preventDefault()
    dispatch({type: 'aboutShow'})
  }
  return (
    <div className="ui-footer">
      <ul className="action-links">
        <li>
          <a href="#" onClick={onClickInfo}>
            <IconInfo />
            <span>About</span>
          </a>
        </li>
      </ul>
      <StateViewer />
    </div>
  )
}

export default UIFooter