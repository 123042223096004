import React, { Fragment } from 'react'
import { useStateContext } from '../state'
import { IconDevice } from './Icons'

const UIPortraitMode = () => {
  const [{orientation, appName}, dispatch] = useStateContext();
  return (
    <Fragment>
      <div className="ui-portrait-mode">
        <div className="text-prompt">
          <h2>Please rotate your device</h2>
          <small>{appName} works best in landscape orientation.</small>
          {/* <small><br />orientation: {orientation}</small> */}
        </div>

        <div className="device-wrapper">
          <IconDevice />
        </div>

      </div>
    </Fragment>
  )
}

export default UIPortraitMode