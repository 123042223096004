import React, {useState} from 'react'
import { useStateContext } from '../state'
import './StateViewer.scss'
import {IconChevronUp, IconChevronDown} from './Icons'

const StateViewer = () => {
  const [state] = useStateContext()
  const [isExpanded, setIsExpanded] = useState(false)
  const iconClasses = 'w-6 h-6'

  const toggleViewer = () => {
    setIsExpanded(!isExpanded)
  }

  let toggleButton = () => {
    return (
      <a onClick={toggleViewer} className="block w-full">
        {isExpanded ? <IconChevronDown className={iconClasses} /> : <IconChevronUp className={iconClasses}  />}
      </a>
    )
  }

  let heightClass = isExpanded ? 'h-56' : 'h-6'

  let stateJSON
  try {
    stateJSON = JSON.stringify(state, null, 2)
  } catch (err) {
    stateJSON = JSON.stringify(err, null, 2)
  }


  return (
    <div className={`state-viewer ${heightClass}`}>
      <div className="state-viewer-toggle">
        {toggleButton()}
      </div>
      {isExpanded &&
        <code className="text-xs flex-1">
          <pre className="p-4">
            {stateJSON}
          </pre>
        </code>
      }
    </div>
  )
}

export default StateViewer