import React from 'react'
import { useStateContext } from '../state'
import { IconEdit, IconQRCode, IconCamera, IconInfo, IconSettings } from './Icons'
import QRCode from './QRCode'
import './modalShare.scss'

const ModalShare = () => {
  const [{ modal }, dispatch] = useStateContext();
  return (
    <div className="modal--content modal-share">
      <h3>Share me...</h3>
      <div className="qr-code-wrapper">
        <QRCode size={256} />
      </div>
      <p>...</p>
    </div>
  )
}

export default ModalShare