import React, {useEffect, useContext} from 'react'
import App from './App'
import { StateProvider, stateReducer, defaultState} from './state'

const AppState = () => {
  useEffect(() => {
    console.log(`%c ::  init  :: `, 'color: gray;')
  }, [])

  return (
    <StateProvider initialState={defaultState} reducer={stateReducer}>
      <App />
    </StateProvider>
  )
}

export default AppState;