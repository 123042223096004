import React from 'react'
import { useStateContext } from '../state'
import { IconEdit, IconQRCode, IconCamera, IconInfo, IconSettings } from './Icons'

const ModalAbout = () => {
  const [state, dispatch] = useStateContext();
  return (
    <div className="modal--content modal-share">
      <h3>About</h3>
      <p>
        Credits...
      </p>
    </div>
  )
}

export default ModalAbout