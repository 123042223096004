import * as PIXI from 'pixi.js'
import 'pixi-sound'
let app, config, preloader
let currentBars, currentBarsData
let currentBarsIndex = {}
let container
let keyList = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\']
let keyMap = {}

const loader = PIXI.Loader.shared

import woodTextureSrc from '../../img/wood/holzv2_06.jpg'

let defaultConfig = {
  antialias: true,
  autoresize: true,
  backgroundColor: 0x061639,
}

const play_sound = (soundId) => {
  loader.resources[soundId].sound.play()
}

const get_bar_by_index = (id) => {
  let foundBar = Object.keys(currentBarsIndex).reduce((match, barId) => {
    let currentBar = currentBarsIndex[barId]
    if (currentBar.order === id) {
      match = currentBar
    }
    return match
  }, undefined)
  return foundBar
}

const strike_bar = (barId) => {
  let targetBar = currentBarsIndex[barId]
  let soundId = get_sound_id(barId)
  play_sound(soundId)
  targetBar.bar.tint = 0xBBBBBB

  // Ensure bar is un-highlighted if called programatically
  // @TODO: Only call if there is not currently a touch down
  setTimeout(() => {
    targetBar.bar.tint = 0xFFFFFF;
  }, 500)
}

const create_bar = ({x, y, width, height, id, label}) => {
  var bar = new PIXI.Graphics()
  bar.beginTextureFill({texture: loader.resources.wood_bar.texture})
  bar.lineStyle(4, 0x0, .3)
  bar.drawRoundedRect(
    x,
    y,
    width,
    height,
    10
  )
  bar.endFill()

  // Add text
  const noteTextStyle = {
    fill: ['#00000080'],
    fontSize: 30,
    lineJoin: "round",
  }

  let textX = x + (width/2)
  // let textY = y + 40
  let textY = y + height - 50
  if (width < 50) {
    noteTextStyle.fontSize = 20
    textY = y + 30
  }
  const noteText = new PIXI.Text(label, noteTextStyle)
  noteText.x = textX
  noteText.y = textY
  noteText.anchor.set(0.5, 0.5)

  bar.addChild(noteText);
  return bar
}

const create_bars = (barsData, customProps = {}) => {
  let screenWidth = app.screen.width
  let screenHeight = app.screen.height
  if (screenHeight > screenWidth) {
    // reverse them to force landscape orientation
    screenWidth = screenHeight
    screenHeight = app.screen.width
  }

  let defaultProps = {
    debug: false,
    gutter: 10,
    padding: 20,
    height: screenHeight,
    width: screenWidth,
    x: 0,
    y: 0
  }
  
  let props = {...defaultProps, ...customProps}

  // Adjust width/height for padding
  props.innerWidth = props.width - (defaultProps.padding * 2)
  props.innerHeight = props.height - (defaultProps.padding * 2)

  // Clean up if needed
  if (container) container.destroy()
  container = new PIXI.Container()

  if (props.debug) {
    var outline = new PIXI.Graphics()
    outline.lineStyle(3, 0xFFFF00)
    outline.drawRect(props.padding, props.padding, props.innerWidth, props.innerHeight)
    container.addChild(outline)
  }

  let gutterSpace = (barsData.length -1) * props.gutter
  let barSpace = props.innerWidth - gutterSpace
  let barWidth = barSpace/barsData.length
  
  let newBars = barsData.map((barProps, index) => {
    let barX = ((barWidth + props.gutter) * index) + props.padding
    let barHeight = (props.innerHeight) * barProps.length
    let barY = props.padding + (props.innerHeight - barHeight) / 2
    let barPropsBase = {
      x: barX,
      y: barY,
      width: barWidth,
      height: barHeight
    }

    // Create bar
    let bar = create_bar({...barPropsBase, ...barProps})

    // Index bar
    currentBarsIndex[barProps.id] = {
      order: index,
      id: barProps.id,
      bar: bar,
      props: barProps
    }

    return bar
  })

  // Add bars to container
  newBars.map((bar) => {
    container.addChild(bar)
  })

  // position
  container.x = props.x
  container.y = props.y
  app.stage.addChild(container)
  return newBars
}

const render_preloader = (percent) => {
  let x = 0
  let y = 0
  let width = app.screen.width
  let progressWidth = width * percent/100
  let preloaderIsNew = (!preloader)
  if (preloaderIsNew) {
    preloader = new PIXI.Graphics()
  } else {
    preloader.clear()
  }

  // draw background bar
  preloader.beginFill(0x000000, 0.5)
  preloader.drawRect(x, y, width, 5)
  preloader.endFill()

  // Draw progress bar
  preloader.beginFill(0xDE3249)
  preloader.drawRect(x, y, progressWidth, 5)
  preloader.endFill()

  if (preloaderIsNew) {
    app.stage.addChild(preloader)
  }
}

const program_bars = (bars) => {
  bars.map(program_bar)
}

const program_bar = (bar, id) => {
  bar.interactive = true
  bar.buttonMode = true

  const mobileOnly = false
  const downEvt = mobileOnly ? 'touchstart' : 'pointerdown'
  const upEvt = mobileOnly ? 'touchend': 'pointerup'
  const upOutsideEvt = mobileOnly ? 'touchendoutside': 'pointerupoutside'

  const bar_store = get_bar_by_index(id)
  bar.on(downEvt, () => {
    strike_bar(bar_store.id)
  })
  bar.on(upEvt, () => {
    bar.tint = 0xFFFFFF;
  })
  bar.on(upOutsideEvt, () => {
    bar.tint = 0xFFFFFF;
  })
}

const bars_key_callback = (evt) => {
  if (evt.key in keyMap) {
    let soundId = keyMap[evt.key]
    console.log('PLAY SOUND', evt.key, soundId)
    strike_bar(soundId)
  }
}

const program_keys = (bars) => {
  keyMap = bars.reduce((map, bar, i) => {
    // console.log('i', i, bar)
    let keyName = keyList[i]
    map[keyName] = bar.id
    return map
  }, {})

  document.addEventListener('keydown', bars_key_callback)
}

const render_xylo = (bars) => {
  // Populate and render bars
  let props = {}
  props.height = app.screen.height - 40
  props.y = 20

  currentBars = create_bars(bars, props)
  // Program bars
  program_bars(currentBars)
  program_keys(bars)
}

const on_assets_loaded = (bars) => {
  console.log('all assets loaded...')
  render_xylo(bars)
}

// Given a bar id, formats the audio resource identifier needed
const get_sound_id = (barId) => {
  return `bar_audio_${barId}`
}

// Setup app
const setup = (el, bars = []) => {
  // Create PIXI.js app
  let width = window.innerWidth
  let height = window.innerHeight
  let options = {
    width, height
  }
  app = new PIXI.Application(options)
  app.renderer.antialias = config.antialias
  app.renderer.autoResize = config.autoresize
  app.renderer.backgroundColor = config.backgroundColor

  // Add assets to loader: image
  loader.add('wood_bar', woodTextureSrc)
  
  // Add assets to loader: audio
  bars.map((bar) => {
    let soundId = get_sound_id(bar.id)
    loader.add(soundId, bar.audio)
  })

  // Kick off loader
  loader.load((loader, assets) => {
    // Asset loading complete
    on_assets_loaded(bars)
  })

  // called once per loaded/errored file
  loader.onProgress.add((loader, asset) => {
    // console.log(`%cLoading ${loader.progress}%`, 'color: gray;')
    render_preloader(loader.progress)
  }); 

  // called once per errored file
  loader.onError.add((loader, asset) => {
    console.error('Error loading asset', asset)
  })

  // called once per loaded file
  loader.onLoad.add((loader, asset) => {
    // console.log('%cloaded asset:', 'color: blue;', asset)
  })

  el.appendChild(app.view)
}

const xylo = ({el, bars, options = {}}) => {
  config = {...defaultConfig, ...options}
  currentBarsData = bars
  setup(el, currentBarsData)
}

export default xylo